.card-title {
    font-size: 2.5em;
}

.card-subtitle {
    font-size: 1.2em;
    margin-bottom: 1em;
    /* font-weight: bold ; */
}

.header-card {
    overflow-wrap: break-word;
}

.tx-card {
    overflow-wrap: break-word;
}

.column-title {
    font-size: 1.2em;
}

.input-card {
    overflow-wrap: break-word;
}

.output-card {
    overflow-wrap: break-word;
}

.bold {
    font-weight: bold;
}

.pad-down {
    padding-bottom: 10px;
}

.margin-float {
    margin-top: 10px;
    margin-bottom: 10px;
}



.right-align-column {
    text-align: right;
}

@media only screen and (max-width : 992px) {
    .right-align-column {
        text-align: left;
    }
}