
@import 'materialize-css/sass/components/_color-variables';
$primary-color: color('green', 'lighten-2') !default;
$primary-color-light: lighten($primary-color, 15%) !default;
$primary-color-dark: darken($primary-color, 15%) !default;

$secondary-color: color("green", "darken-2") !default;
$success-color: color("green", "base") !default;
$error-color: color("red", "base") !default;
$link-color: color("green", "darken-2") !default;
// $navbar-font-color: slategrey !default;

// dark mode
// $dark-primary-color: color('grey', 'darken-4') !default;
$dark-primary-color: #151e22 !default;
$dark-primary-color-light: lighten($dark-primary-color, 15%) !default;
$dark-primary-color-dark: darken($dark-primary-color, 15%) !default;

$dark-secondary-color: color("blue-grey", "darken-4") !default;
$dark-success-color: color("green", "base") !default;
$dark-error-color: color("red", "base") !default;
$dark-link-color: color("green", "darken-2") !default;


$transition-effects: color 1s ease, background-color 1s ease;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: $transition-effects;
    &.dark {
        color: slategrey;
        background-color: #181f24;
        a {
          color: #1f75a0;
        }
        nav {
            background-color: $dark-primary-color;
            color: #1f75a0;
            a {
                color: #1f75a0;
            }
        }
        main {
            // background-image: url("../../public/assets/img/escheresque_ste.png");
            // background-repeat: repeat;
            background-image: none;
            background-color: #181f24;
        }
        input#search {
          color: slategrey;
          border-bottom: 1px solid #1f75a0;
        }
        input#search[type="text"]:not(.browser-default):focus:not([readonly]) {
            border-bottom: 1px solid #1f75a0;
            box-shadow: 0 1px 0 0 #1f75a0;
        }
        .input-field .prefix.active {
            color: #1f75a0;
        } 
        .card-panel {
            background-color: $dark-secondary-color;
        }
        .card, .card-tabs, .tabs, .card-content  {
            background-color: $dark-secondary-color;
        }
        .card-title {
          color: #1f75a0;
      }
        footer {
            color: slategrey;
            background-color: $dark-primary-color;
        }
        .footer-copyright {
          color: slategrey;
        }
        .modal {
          background-color: $dark-primary-color;
        }
        .modal-content {
          color: slategrey;
        }
        .modal-content h4 {
          color: #1f75a0;
          padding-bottom: 15px;
        }
        .modal-footer {
          background-color: $dark-primary-color;
        }
        .select-dropdown {
          color: slategrey;
        }
        .select-wrapper input.select-dropdown:focus {
          border-bottom: 1px solid #1f75a0;
        }
        .dropdown-content, .dropdown-content li > span {
          color: #1f75a0;
          background-color: $dark-primary-color;
        }
        .select-wrapper .caret {
          fill: #1f75a0;
        }
        
        .divider {
          // background-color: #0f0f0f;
          background-color: #3e6477;
        }
        .collapsible-header {
          background-color: $dark-secondary-color;
          border-color: #3e6477;
        }
        .collapsible-header:focus {
          background-color: #2c424d;
          border-color: #3e6477;
        }
        ul.collapsible, .collapsible-body {
          border-color: #3e6477;
        }
        .btn {
          background-color: #1f75a0;
        }
        .btn:hover {
          background-color: #3383ac;
        }
       

    }

  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  /* make bottom footer work with materialize css */
  #root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
  
  main {
    flex: 1 0 auto;
    padding-top: 15px;
    background-image: url("../../public/assets/img/y-so-serious.png");
    background-repeat: repeat;
    background-color: #cccccc;
  }

  @import 'materialize-css/sass/materialize';


    // /* make bottom footer work with materialize css */
    // #root {
    //     display: flex;
    //     min-height: 100vh;
    //     flex-direction: column;
    //   }
      
    //   main {
    //     flex: 1 0 auto;
    //   }
