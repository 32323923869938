.navbar {
    display: flex;
    justify-content: space-around;
    align-items:center;
}

form.search {
    max-width: 500px;
    flex-grow: 2;
    flex-shrink: 2;
}

.search .input-field {
    max-width: 500px;
    flex-grow: 2;
    flex-shrink: 2;
}

.brand {
    font-size: 2em;
}

.modal {
    overflow-y: visible;
}

.dropdown {
    width: inherit;
}

input#search {
    color: white;
    border-bottom: 1px solid white;

}

input#search[type="text"]:not(.browser-default):focus:not([readonly]) {
    border-bottom: 1px solid white;
    box-shadow: 0 1px 0 0 white;
}

.input-field .prefix.active {
    color: white;
} 